import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import route from '../../../src/common/utils/route'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingQuality = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`${'banners'}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`${'banners'}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos Quality" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          En <strong>{getFullCompany()}</strong> trabajamos con las marcas de
          neumáticos con el mejor equilibrio calidad y precio del mercado
          pudiendo ofrecer{' '}
          <strong>
            los mejores precios en las Islas Canarias con una calidad de
            servicio excelente
          </strong>
          . Estas segundas marcas te permitirán ahorrar en tu compra respecto a
          las marcas premium, manteniendo excelentes prestaciones de{' '}
          <strong>seguridad y fiabilidad</strong>.
        </p>

        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />

        <section className={styles.section}>
          <h2>Gran selección de Neumáticos Quality </h2>
          <p>
            Las segundas marcas son una gran elección para aquellos conductores
            que requieren un cambio de neumáticos y necesitan{' '}
            <strong>ahorrar en la inversión sin perder calidad</strong> en las
            ruedas que compra.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            En {getFullCompany()} disponemos de una excelente selección de
            Marcas Quality que poco o nada tienen que envidiar a las marcas
            premium más allá de su reconocimiento. Algunas de estas marcas{' '}
            <strong>
              pertenecen a fabricantes de neumáticos premium y se comportan como
              su segunda marca
            </strong>
            . Son ruedas de una excelente calidad a precios ajustados que
            ofrecen un muy buen rendimiento de la rueda en prácticamente
            cualquier situación.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Estas marcas{' '}
            <strong>
              comparten materiales y procesos de fabricación con las marcas más
              importantes
            </strong>{' '}
            reconocidas por el gran público. Por tanto, las diferencias
            apreciables para el conductor estándar no distan demasiado de las
            prestaciones que ofrece un neumático premium.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Los neumáticos son uno de los elementos de mayor importancia para la
            seguridad del coche. Las marcas quality son una{' '}
            <strong>
              alternativa más económica que te permite disfrutar de la
              conducción sin renunciar a la seguridad y la calidad
            </strong>{' '}
            a un precio mucho más barato.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Algunas de nuestras marcas quality más vendidas son Kleber,
            BFGoodrich, Falken, Firestone, Maxxis y Khumho Tyre.
          </p>
        </section>
      </article>
    </div>
  )
}

export default LandingQuality

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-quality'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-quality')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)
